import React, { useCallback, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core";
import { IconButton, styled, Theme, useTheme } from "@mui/material";
import {
  calculateIconSize,
  OVERLAY_TABS,
  QUICK_MOVE_DIRECTION,
} from "../utils/Constants";
import { setModalState } from "../store/modal/ModalActions";
import { ViewContext } from "../context/view-context";
import { itemManagementModalId } from "./item-management/ItemManagementModal";
import { ColorManagementModalId } from "./color-mangement-modal/ColorManagement";
import { VariationManagementModalId } from "./variation-management-modal/VariationManagement";
import { selectConfigValue } from "../store/app-config/AppConfigSelectors";
import { itemManagementSFCCModalId } from "./item-management-SFCC/ItemManagementSFCCModal";
import {
  analyticsDataLoader,
  resetSingleProducAnalyticView,
} from "../store/product/ProductActions";
import AppState from "../store/AppState";
import {
  selectHasChildren,
  selectProductAnalyticsData,
  selectProductFetched,
  selectProductType,
} from "../store/product/ProductSelectors";
import { LeadingImageModalId } from "./leading-image-modal/LeadingImageModal";
import { AdapterInfoObject } from "../utils/AppConfigUtils";
import { selectAdapterInfo } from "../store/adapter-config/AdapterConfigSelectors";
import { selectWatchedProductsList } from "../store/product-list/ProductListSelectors";
import {
  addProductToWatchList,
  refreshProductFromList,
  removeProductFromWatchList,
} from "../store/product-list/ProductListActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store/store-list/StoreListSelectors";
import { getUserEmail } from "../services/UserService";
import { loadSingleProductAnalytics } from "../store/product/ProductActions";
import {
  selectAnalyticsStartDate,
  selectAnalyticsEndDate,
  selectDateRange,
} from "../store/product/ProductSelectors";
import {
  selectSortingRule,
  selectSortingRulesList,
} from "../store/product-list/ProductListSelectors";
import { BusinessRulesListDetails } from "../store/product-list/ProductListTypes";
import { calculateStartEndDate } from "../utils/ProductUtil";
import { selectSystemGeneratedRules } from "../store/businessRules-list/BusinessRuleslistSelectors";
import {
  InventoryIcon,
  VariationIcon,
  AnalyticsIcon,
  HighlightedEyeIcon,
  UnWatchedEyeIcon,
  MoveRightArrowIcon,
  MoveLeftArrowIcon,
  ColorManagementIcon,
  ReassignVariationIcon,
} from "../assets";
import Icons from "./common/Icons";
import { selectOverlay } from "store/overlay/OverlaySelectors";
import CachedIcon from "@material-ui/icons/Cached";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { VariationReassignmentModalId } from "./variation-reassignment-modal/VariationReassignmentModal";

interface StyleProps {
  productActionFZ: string;
  productActionDimensions: string;
  column: number;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    actionContainer: {
      position: "absolute",
      right: " 1em",
      top: "1.9em",
      "& .product-tile__actions": {
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
        fontSize: (props: StyleProps) => props.productActionFZ,
        height: (props: StyleProps) => props.productActionDimensions,
        width: (props: StyleProps) => props.productActionDimensions,
        "& svg": {
          width: (props) => `${calculateIconSize(props.column)}rem`,
          height: (props) => `${calculateIconSize(props.column)}rem`,
          padding: 2,
        },
        cursor: "pointer",
        marginBottom: "5px",
        borderRadius: "2px",
        background: "#78a6be",
        "& .eyeIcon": {
          padding: (props: StyleProps) => (props.column === 6 ? 2.5 : 5),
        },
        "& .arrowIcon": {
          padding: (props: StyleProps) => (props.column === 6 ? 4 : 7),
        },
        "& .iconStyle": {
          padding: (props: StyleProps) => (props.column === 6 ? 3 : 5),
        },
      },
    },
  }),
);

const StyledInventoryIcon = styled(
  InventoryIcon,
)` &:hover path { fill: #F18221; // Change fill color on hover } `;

const styles = (theme: Theme) => {
  return {
    analyticsColor: {
      backgroundColor: theme.palette.primary.main,
    },
    highlightedEye: {
      padding: 0,
    },
  };
};

interface Props {
  productId: string;
  position: number;
  isSelected: boolean;
  hasProductsSelectedFromList: boolean;
  onQuickMoveCompleteAction: (productId: string, moveTo: number) => void;
  setIsAnalyticsDisplayed: (value: React.SetStateAction<boolean>) => void;
  isAnalyticsDisplayed: boolean;
}
const ProductActions = ({
  productId,
  isSelected,
  hasProductsSelectedFromList,
  onQuickMoveCompleteAction,
  setIsAnalyticsDisplayed,
  isAnalyticsDisplayed,
}: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const viewContext = useContext(ViewContext);
  const classes = useStyles({ ...viewContext.styles });
  const theme = useTheme();
  const { analyticsColor, highlightedEye } = styles(theme);
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const isLeadingImage = adapterInfo?.capabilities?.includes(
    "leading-image",
  ) as boolean;
  const isVariationGroups = adapterInfo?.operations?.includes(
    "GET /v1/{accountId}/products/{baseProductId}/variation-groups",
  ) as boolean;
  const showItemManagement = "true";
  const bulkUpdateEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "bulkUpdateEnabled"),
    ) === "true" || showItemManagement === "true";

  const hasChildren =
    useSelector((state: AppState) => selectHasChildren(state, productId)) ||
    true;
  const productType = useSelector((state: AppState) =>
    selectProductType(state, productId),
  );

  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );

  const productTypeCode = useMemo(
    () => fetchedProduct?.typeCode,
    [fetchedProduct],
  );
  const watchedProductsList = useSelector(selectWatchedProductsList);

  const currentStoreId = useSelector(selectCurrentStoreId);

  const localeId = useSelector(selectCurrentLocale) || "";

  const catalogId = useSelector(selectCurrentCatalogId) || "";

  const selectedAccountEmail = useSelector(getUserEmail);

  const isProductAlertsEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableProductAlerts"),
  );

  const productCode = fetchedProduct?.code ?? "";

  const [showHighlightedEye, setShowHighlightedEye] = useState<boolean>(false);

  const productAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, productCode),
  );

  const selectedOverlay = useSelector(selectOverlay);
  const startDate = useSelector(selectAnalyticsStartDate);
  const endDate = useSelector(selectAnalyticsEndDate);
  const selectedDateRange = useSelector(selectDateRange);
  const sortingRulesList = useSelector(selectSortingRulesList);
  const systemGeneratedRulesList = useSelector(selectSystemGeneratedRules);
  const allSortingRulesList = [
    ...systemGeneratedRulesList,
    ...sortingRulesList,
  ];

  const selectedRule = useSelector(selectSortingRule);

  const isCurrentProductInWatchlist = useMemo(() => {
    if (watchedProductsList && watchedProductsList.length) {
      return (
        watchedProductsList.findIndex(
          (product) => product.productId === productId,
        ) !== -1
      );
    }
    return false;
  }, [watchedProductsList, productId]);

  const variationBaseId = fetchedProduct?.variationBaseId;
  const isVariationProduct =
    variationBaseId !== "" && variationBaseId !== undefined;
  const hasColors = fetchedProduct?.hasColors;
  const hasManageableColors =
    fetchedProduct?.hasManageableColors && fetchedProduct?.colors.length > 0;
  const openColorWayManagement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(setModalState(ColorManagementModalId, true, { productId }));
  };

  const openVariationManagement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(
      setModalState(VariationManagementModalId, true, { variationBaseId }),
    );
  };

  const openItemManagement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();

    if (bulkUpdateEnabled) {
      dispatch(setModalState(itemManagementModalId, true, { productId }));
    } else {
      dispatch(setModalState(itemManagementSFCCModalId, true, { productId }));
    }
  };

  const openVariationReassignment = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(setModalState(VariationReassignmentModalId, true, { productId }));
  };

  const openLeadingImage = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(setModalState(LeadingImageModalId, true, { productId }));
  };

  const quickMoveProductsToLeft = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onQuickMoveCompleteAction(productId, QUICK_MOVE_DIRECTION.LEFT);
  };

  const quickMoveProductsToRight = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onQuickMoveCompleteAction(productId, QUICK_MOVE_DIRECTION.RIGHT);
  };

  const toggleProductAnalytics = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (
      (selectedOverlay !== OVERLAY_TABS.ANALYTICS_OVERLAY &&
        !productAnalytics?.showAnalytics) ||
      (selectedOverlay === OVERLAY_TABS.ANALYTICS_OVERLAY &&
        productAnalytics?.showAnalytics === false)
    ) {
      setIsAnalyticsDisplayed(true);
      dispatch(analyticsDataLoader(productCode));
      const sortingRule = allSortingRulesList?.find(
        (rule) => rule.ruleId === selectedRule,
      );
      if (sortingRule) {
        const { startDate, endDate } = calculateStartEndDate(
          sortingRule as BusinessRulesListDetails,
        );
        if (startDate && endDate) {
          const analyticsPayload = {
            startDate: startDate,
            endDate: endDate,
            productCode: productCode,
          };
          dispatch(loadSingleProductAnalytics(analyticsPayload));
        }
      } else {
        const fromDate = selectedDateRange.startDate;
        const toDate = selectedDateRange.endDate;
        if (fromDate !== startDate || toDate !== endDate) {
          const analyticsPayload = {
            startDate: fromDate,
            endDate: toDate,
            productCode: productCode,
          };
          dispatch(loadSingleProductAnalytics(analyticsPayload));
        }
      }
    } else {
      setIsAnalyticsDisplayed(false);
      dispatch(resetSingleProducAnalyticView(productCode));
    }
  };

  const quickMoveIconsVisibility = isSelected || !hasProductsSelectedFromList;

  const setUnsetWatchState = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      const payload = {
        productId,
        storeId: currentStoreId || "",
        watcher: selectedAccountEmail,
      };
      if (isCurrentProductInWatchlist) {
        dispatch(removeProductFromWatchList(payload));
      } else {
        dispatch(addProductToWatchList(payload));
      }
    },
    [
      dispatch,
      isCurrentProductInWatchlist,
      currentStoreId,
      productId,
      selectedAccountEmail,
    ],
  );

  const fetchSingleProductUpdatedDetails = useCallback(
    (productId: string, event) => {
      event.stopPropagation();
      let payload = {
        productId,
        catalogId,
        localeCode: localeId,
        storeId: currentStoreId || "",
      };
      dispatch(refreshProductFromList(payload));
    },
    [catalogId, localeId, currentStoreId, dispatch],
  );

  return (
    <div className={classes.actionContainer}>
      <IconButton
        color="menu"
        title={intl.formatMessage({
          id: "productActions.refreshIcon",
          defaultMessage: "Refresh Product",
        })}
        aria-label="RefreshProduct"
        component="div"
        onClick={openItemManagement}
        className="product-tile__actions"
      >
        <CachedIcon
          titleAccess={`Last cached at ${new Date(
            fetchedProduct?.cachedAt as Date,
          )} `}
          onClick={(event) =>
            fetchSingleProductUpdatedDetails(productId, event)
          }
          className="refreshIcon"
        />
      </IconButton>
      {hasChildren &&
        productType !== "ProductBundle" &&
        productType !== "ProductSet" && (
          <>
            {hasColors && hasManageableColors && (
              <IconButton
                color="menu"
                title={intl.formatMessage({
                  id: "productActions.colorManagement",
                  defaultMessage: "Color Mgmt",
                })}
                aria-label="ColorWayManagement"
                component="div"
                onClick={openColorWayManagement}
                className="product-tile__actions"
              >
                <ColorManagementIcon className="iconStyle" />
              </IconButton>
            )}
            {isVariationProduct && productTypeCode !== "Variation Product" && (
              <IconButton
                color="menu"
                title={intl.formatMessage({
                  id: "productActions.inventoryManagement",
                  defaultMessage: "Inventory Mgmt",
                })}
                aria-label="InventoryManagement"
                component="div"
                onClick={openItemManagement}
                className="product-tile__actions"
              >
                <StyledInventoryIcon />
              </IconButton>
            )}
            {isVariationProduct && (
              <IconButton
                color="menu"
                title={intl.formatMessage({
                  id: "productActions.variationReassignment",
                  defaultMessage: "Reassign Variations",
                })}
                aria-label="ReassignVariations"
                component="div"
                onClick={openVariationReassignment}
                className="product-tile__actions"
              >
                <ReassignVariationIcon className="iconStyle" />
              </IconButton>
            )}
            {isVariationGroups &&
              isVariationProduct &&
              productTypeCode !== "Variation Product" && (
                <IconButton
                  color="menu"
                  title={intl.formatMessage({
                    id: "productActions.variationGroupManagement",
                    defaultMessage: "Variation Group Mgmt",
                  })}
                  aria-label="VariationGroupManagement"
                  component="div"
                  onClick={openVariationManagement}
                  className="product-tile__actions"
                >
                  <VariationIcon />
                </IconButton>
              )}
          </>
        )}
      {isLeadingImage && productTypeCode !== "Variation Product" && (
        <IconButton
          color="menu"
          title={intl.formatMessage({
            id: "productActions.leadingImage",
            defaultMessage: "Image Mgmt",
          })}
          aria-label="LeadingImage"
          component="div"
          onClick={openLeadingImage}
          className="product-tile__actions"
        >
          <Icons iconId="ImageIcon" type="imagebackgroundhover" />
        </IconButton>
      )}
      <IconButton
        color="menu"
        title={intl.formatMessage({
          id: "productActions.analytics",
          defaultMessage: "View Product Analytics",
        })}
        aria-label="ProductAnalytics"
        component="div"
        onClick={toggleProductAnalytics}
        className="product-tile__actions"
        style={isAnalyticsDisplayed ? analyticsColor : {}}
      >
        <AnalyticsIcon />
      </IconButton>
      {isProductAlertsEnabled === "true" && (
        <IconButton
          component="div"
          color="menu"
          className="product-tile__actions"
          title={
            isCurrentProductInWatchlist
              ? intl.formatMessage({
                  id: "productActions.unWatchProduct",
                  defaultMessage: "Unwatch Product",
                })
              : intl.formatMessage({
                  id: "productActions.watchProduct",
                  defaultMessage: "Watch Product",
                })
          }
          onMouseOver={() => setShowHighlightedEye(true)}
          onMouseLeave={() => setShowHighlightedEye(false)}
          onClick={setUnsetWatchState}
        >
          {showHighlightedEye ? (
            <HighlightedEyeIcon style={highlightedEye} />
          ) : isCurrentProductInWatchlist ? (
            <HighlightedEyeIcon style={highlightedEye} />
          ) : (
            <UnWatchedEyeIcon className="eyeIcon" />
          )}
        </IconButton>
      )}
      {!quickMoveIconsVisibility && (
        <>
          <IconButton
            color="menu"
            title={intl.formatMessage({
              id: "productActions.moveRight",
              defaultMessage:
                "Click to move all the selected products to the right of this product",
            })}
            aria-label="quickMoveProductsToRight"
            component="div"
            onClick={quickMoveProductsToRight}
            className="product-tile__actions"
          >
            <MoveRightArrowIcon className="arrowIcon" />
          </IconButton>
          <IconButton
            color="menu"
            title={intl.formatMessage({
              id: "productActions.moveLeft",
              defaultMessage:
                "Click to move all the selected products to the left of this product",
            })}
            aria-label="quickMoveProductsToLeft"
            component="div"
            onClick={quickMoveProductsToLeft}
            className="product-tile__actions"
          >
            <MoveLeftArrowIcon className="arrowIcon" />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default ProductActions;
