import { Theme } from "@mui/material";

export const useStyles = (theme: Theme) => {
  return {
    overlay: {
      position: "absolute" as const,
      backgroundColor: "rgba(0, 0, 0, 0.65)",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: "auto" as const,
      margin: "5px",
    },
    listParent: {
      position: "relative" as const,
      marginTop: "30px !important",
      fontWeight: "bold",
      padding: "10px !important",
    },
    listItem: {
      color: theme.palette.common.white,
      listStyle: "none",
      padding: "0 10px",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    checkBox: {
      color: "white",
      "&.Mui-checked": {
        color: "white",
      },
      "&:hover": {
        color: theme.palette.primary.main,
      },
      marginRight: "10px",
    },
    listItemContainer: {
      display: "flex",
      alignItems: "center",
    },
    loading: {
      position: "absolute" as const,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      padding: "10px",
      borderRadius: "50%",
    },
  };
};
