import React, { useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import {
  selectCurrencyCode,
  selectCurrentStoreId,
} from "../store/store-list/StoreListSelectors";
import AppState from "../store/AppState";
import { selectOverlay } from "../store/overlay/OverlaySelectors";
import {
  CURRENCY_MAP,
  PRODUCT_IMAGE_BADGE_MARGIN_TOP,
} from "../utils/Constants";
import { useIntl } from "react-intl";
import { ViewContext } from "../context/view-context";
import Tooltip from "./common/ToolTip";
import { selectProductImagePrefix } from "store/app-config/AppConfigSelectors";
import { isAbsolutePath, isListPriceGreaterThanPrice } from "utils/ProductUtil";
import NoImageIcon from "../assets/NoImage.png";
import { BadgeChip } from "./product-title-content/ProductTileContentStyles";
import { ProductBadge } from "assets";

const StyledProductDetail = styled.div<{
  selectedOverlay: string;
}>`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
`;

const ImageContainer = styled.div<{ height?: string | number }>`
  overflow: hidden;
  height: ${({ height }) =>
    height}; /* Apply height from prop or default to auto */
  & .image {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 15 / 10;
    object-fit: contain;
  }
`;

const StyledLabel = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${theme.palette.primary.main};
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    width: 200px;
    position: absolute;
    top: 10px;
    right: 5px;
    border: 1px;
    border-top-left-radius: 50px;
    padding: 2px;
  `}
`;

interface Props {
  name: string;
  price: string | null;
  priceRange: number[] | null;
  code?: string;
  type?: string;
  image?: string | null;
  productScore?: number | undefined;
  isRecentlyAdded?: boolean | undefined;
  badgeLabel?: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productName: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "100%",
      display: "block",
      paddingLeft: 15,
      paddingRight: 15,
    },
    product: {
      paddingTop: 10,
      display: "flex",
      flexDirection: "column",
    },
  }),
);

const ProductDetail: React.FC<Props> = (props) => {
  const { name, price, priceRange, type, image, isRecentlyAdded, badgeLabel } =
    props;
  const intl = useIntl();
  // const classes = useStyles();
  const storeId = useSelector(selectCurrentStoreId);
  const productImagePrefix = useSelector((state: AppState) =>
    selectProductImagePrefix(state, storeId),
  );
  const selectedOverlay = useSelector((state: AppState) =>
    selectOverlay(state),
  );
  const currencyCode = useSelector(selectCurrencyCode);
  let currencySymbol = "$";
  if (CURRENCY_MAP[currencyCode]) {
    currencySymbol = CURRENCY_MAP[currencyCode];
  }
  const view = useContext(ViewContext);
  const changeStyles =
    view.styles.column === 6
      ? { fontSize: "0.875rem" }
      : { fontSize: "inherit" };
  const productImage = image
    ? isAbsolutePath(image)
      ? image
      : productImagePrefix + image
    : NoImageIcon;

  const classes = useStyles();
  const isListPriceGreaterThanSalePrice = isListPriceGreaterThanPrice(
    price,
    priceRange,
  );
  const variantType = isListPriceGreaterThanSalePrice
    ? "discountedPrice"
    : "price";
  const lowPrice = priceRange ? `${currencySymbol}${priceRange[0]}` : null;
  const highPrice = priceRange ? `${currencySymbol}${priceRange[1]}` : null;
  const isLowAndHighPriceAreSame = lowPrice === highPrice;
  const imageMarginTop = `${PRODUCT_IMAGE_BADGE_MARGIN_TOP.get(view.styles.column) || 30}px`;

  return (
    <StyledProductDetail selectedOverlay={selectedOverlay}>
      <ImageContainer>
        {productImage ? (
          <img
            className="image"
            src={`${productImage}`}
            alt={name}
            draggable={false}
          />
        ) : null}
      </ImageContainer>
      {badgeLabel && (
        <BadgeChip
          icon={<ProductBadge />}
          label={badgeLabel}
          marginTop={imageMarginTop}
        />
      )}
      <div className={classes.product}>
        <Tooltip tooltipTitle={name} interactive enterDelay={500}>
          <Typography
            style={changeStyles}
            className={classes.productName}
            variant="title"
          >
            {name}
          </Typography>
        </Tooltip>
        <div>
          <Typography style={changeStyles} variant="listPrice">
            {isListPriceGreaterThanSalePrice
              ? `${currencySymbol}${price}  `
              : ""}
          </Typography>
          <Typography style={changeStyles} variant={variantType}>
            {priceRange
              ? type === "Grouped Product" || type === "Product Set"
                ? `Starting ${type === "Product Set" ? "from" : "at"} ${lowPrice}`
                : lowPrice && highPrice
                  ? isLowAndHighPriceAreSame
                    ? `${lowPrice}`
                    : `${lowPrice}-${highPrice}`
                  : `${lowPrice}`
              : type?.includes("Bundle")
                ? price && `${currencySymbol}${price}`
                : ""}
          </Typography>
        </div>
        {isRecentlyAdded === true ? (
          <StyledLabel>
            {intl.formatMessage({
              id: "productDetail.recentlyAdded",
              defaultMessage: "Recently Added",
            })}
          </StyledLabel>
        ) : null}
      </div>
    </StyledProductDetail>
  );
};

export default ProductDetail;
